import _ from "lodash";

export function displayInvestmentAwardedDropAction(investment, dealBuilder) {
  return (
    investmentId(investment, dealBuilder) &&
    _.get(investment, "state") === "under_contract"
  );
}
export function matchingInvestmentGroup(investment, dealBuilder) {
  return _.find(_.get(dealBuilder, "investmentGroups", []), function (group) {
    return _.find(group.investments, function (inv) {
      const localInvestmentId =
        _.get(investment, "existingInvestment.id") ||
        _.get(investment, "id") ||
        _.get(investment, "temporaryId");

      return _.get(inv, "existingInvestment.id") === localInvestmentId;
    });
  });
}
export function focalInvestment(investment, dealBuilder) {
  if (matchingInvestmentGroup(investment, dealBuilder)) {
    return _.find(
      matchingInvestmentGroup(investment, dealBuilder).investments,
      function (inv) {
        const localInvestmentId =
          _.get(investment, "existingInvestment.id") ||
          _.get(investment, "id") ||
          _.get(investment, "temporaryId");

        return _.get(inv, "existingInvestment.id") === localInvestmentId;
      },
    );
  } else {
    return investment;
  }
}
export function investmentId(investment, dealBuilder) {
  return (
    _.get(focalInvestment(investment, dealBuilder), "existingInvestment.id") ||
    _.get(focalInvestment(investment, dealBuilder), "id") ||
    _.get(focalInvestment(investment, dealBuilder), "temporaryId")
  );
}
export function localCapitalProviders(investment, dealBuilder) {
  return _.get(
    focalInvestment(investment, dealBuilder),
    "existingInvestment.capitalProviders",
    _.get(focalInvestment(investment, dealBuilder), "capitalProviders", []),
  );
}
export function providersFetchRequestKey(investment) {
  return `investment_ownership_interests_${investment?.id}`;
}
export function awardedProvidersFetchRequestKey(investment) {
  return `investment_ownership_interests_${investment?.id}_awarded`;
}
